<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>
    
    <img width="120px" src="https://paraiba.pb.gov.br/marca-do-governo/GovPBT.png" class="layout-menu-button">
    
    <span class="layout-topbar-user-name"> {{ $auth.user.nomecompleto }} </span>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="toggleMenu">
      <i class="pi pi-user"></i>
    </button>
    <Menu
      ref="profilemenu"
      class="profile-menu-item-custom"
      :model="items"
      :popup="true" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ],
    }
  },

  methods: {
    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
  },
}
</script>
<style>
.profile-menu-item-custom span {
  padding-left: 5px;
  padding-right: 5px;
}

@media print {
  .layout-topbar {
    display: none;
  }
}

</style>
